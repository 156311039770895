// src/firebaseConfig.js

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCrFSFxTFpijfqv2pk4Sx4haODpzIXD__Q",
  authDomain: "encryptosign-424f3.firebaseapp.com",
  projectId: "encryptosign-424f3",
  storageBucket: "encryptosign-424f3.appspot.com",
  messagingSenderId: "900459979756",
  appId: "1:900459979756:web:35f5c7fea73fc88ac256c9"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };